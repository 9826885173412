.skills-container {
    background-color: transparent;
    padding-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

/* Card Dark Mode */
.skills-card.dark {
    background-color: #000; /* Solid black background */
    color: #fff; /* White text for readability */
    padding: 20px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
    max-width: 1000px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.2); /* Light border to define the card */
}

/* Card Light Mode */
.skills-card.light {
    background-color: #ffffff;
    color: #333333;
    padding: 20px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
}

.skills-card h2 {
    margin-bottom: 20px; /* Add space between heading and accordion */
    text-align: center;
}

/* Accordion Styles */
.accordion {
    width: 100%; 
}

.accordion-item {
    background-color: transparent; 
    border: none;
}

.accordion-header {
    background-color: transparent;
} 

/* Accordion Button Dark Mode */
.accordion-button.dark {
    background-color: #000 !important; /* Dark mode background */
    color: #fff !important; /* Dark mode text */
    border: none;
    box-shadow: none;
    transition: background-color 0.3s ease;
}

/* Change the dropdown arrow color in dark mode */
.accordion-button.dark::after {
    filter: brightness(0) invert(1); /* Invert the arrow color to white */
}

/* Accordion Body Dark Mode */
.accordion-body.dark {
    background-color: #000 !important;
    color: #fff !important;
}

/* Accordion Button Light Mode */
.accordion-button.light {
    background-color: #fff !important; /* Dark mode background */
    color: #333 !important; /* Dark mode text */
    border: none;
    box-shadow: none;
    transition: background-color 0.3s ease;
}

/* Ensure dropdown arrow color in light mode is default (black) */
.accordion-button.light::after {
    filter: none; /* Reset filter for light mode */
}

/* Accordion Body Light Mode */
.accordion-body.light {
    background-color: #fff !important;
    color: #333 !important;
}

strong {
    font-size: 1.2rem;
}

