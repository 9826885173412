.home-container {
    position: relative;
    height: 100vh;
    width: 100%;
}

.centered-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* color: #fff; /* White text color for visibility */
}

.centered-content h1 {
    font-size: 5rem;
    margin: 0;
}

.centered-content p {
    font-size: 1.5rem;
    margin-top: 1rem;
}
  