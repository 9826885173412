/* Main container with flexible height */
.about-container {
    background-color: transparent; /* No background color */
    padding-top: 50px; /* Adjust this value if your navbar height is different */
    display: flex;
    justify-content: center; /* Center the card horizontally */
    width: 100%;
    margin-bottom: 50px;
}

/* Transparent card */
.about-card {
    /* background-color: #000;
    color: #fff; /* White text color for readability */
    padding: 20px;
    border-radius: 12px;
    display: flex;
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center items horizontally */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
    max-width: 1000px; /* Max width for the card */
    width: 100%; /* Take full width within the max-width */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Light border to define the card */

}
  
.profile-image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px; /* Space between the image and text */
}

.profile-image {
    height: 300px;
    width: 300px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.2); /* Slight border for the image */
}

.about-info {
    text-align: center;
}

.about-info h5 {
    margin: 0;
}

.about-info h2 {
    margin-top: 20px;
    text-align: left;
}

.about-info p {
    margin: 0%;
}

.education-item, .experience-item, .leadership-item, .interest-item {
    margin-bottom: 20px;
    margin-left: 20px;
    text-align: left;
}

strong {
    font-size: 1.2rem;
}
