/* Container for the entire section */
.projects-section {
    padding-top: 50px;
    margin-left: 20px;
}

/* Title Styling */
.projects-title.light {
    text-align: center; /* Center the title */
    color: #333; /* Adjust color as needed */
}

.projects-title.dark {
    text-align: center; /* Center the title */
    color: #fff; /* Adjust color as needed */
}

.project-container {
    background-color: transparent;
    padding-top: 10px;
    display: flex;
    justify-content: left;
    align-items: flex-start; /* Align items at the top */
    flex-wrap: wrap; /* Allow wrapping of items */
    gap: 20px; /* Space between cards */
    max-width: 1000px; /* Maximum width of the container */
    margin: 0 auto; /* Center the container */
    
}

/* Project Card Styling */
.project-card.light {
    background-color: #E8E8E8;
    width: 20rem;
    margin-bottom: 20px; /* Margin to space out the cards */
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.project-card.dark {
    background-color: #000;
    width: 20rem;
    margin-bottom: 20px; /* Margin to space out the cards */
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Light border to define the card */
}

/* Adjust the image size */
.project-card img {
    width: 100%;
    height: 400px;
    object-fit: cover; /* Ensure the image covers the area without distortion */
}

/* Title Styling */
.project-title.light {
    color: #333; /* Adjust color as needed */
}

.project-title.dark {
    color: #fff; /* Adjust color as needed */
}

/* Tech Stack (project-card-text) Styling */
.project-card-text.light {
    margin-top: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6); /* Light mode color */
}

.project-card-text.dark {
    margin-top: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7); /* Dark mode color */
}

/* Project Description Styling */
.project-description.light {
    color: #333; /* Light mode description color */
}

.project-description.dark {
    color: #fff; /* Dark mode description color */
}

/* Filter Buttons Styling */
.filter-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
    gap: 10px;
}

.filter-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

.filter-button.light {
    background-color: #f0f0f0;
    color: #000;
}

.filter-button.dark {
    background-color: #333;
    color: #fff;
}

.filter-button:hover {
    opacity: 0.8;
}
