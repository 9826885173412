/* Dark Mode */
body.dark-mode {
    background-color: #121212; /* Dark background */
    color: #ffffff; /* White text */
}

/* Default Light Mode (optional) */
body {
    background-color: #ffffff;
    color: #000000;
}

.scrollable-icons {
    overflow-x: auto; /* Enable horizontal scroll */
    white-space: nowrap; /* Prevent wrapping of icons */
}

.scrollable-icons .nav-link {
    display: inline-block; /* Ensure icons align horizontally */
    margin-left: 10px; /* Add space between icons */
}

.nav-toggle-btn {
    background: transparent;
    border: none;
    padding: 0;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

/* Hover effect for toggle button */
.nav-toggle-btn:hover {
    transform: scale(1.1); /* Enlarge slightly on hover */
    background-color: gray; /* Set hover background color */
}

/* Remove background color on focus and active */
.nav-toggle-btn:focus,
.nav-toggle-btn:active {
    background-color: gray !important;
    box-shadow: none; /* Remove any box shadow that might appear */
    outline: none; /* Remove default outline */
}

/* Styles for night mode (dark mode) */
.dark-mode-btn {
    color: white; /* White icon in dark mode */
}

/* Styles for day mode (light mode) */
.light-mode-btn {
    color: black; /* Dark icon in light mode */
}
