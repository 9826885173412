.contact-section {
    position: relative;
    height: 100vh;
    width: 100%;
}

/* Container for the contact form and content */
.contact-container {
    margin-top: 80px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
}

/* Dark mode styles */
.contact-container.dark {
    background-color: #000;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Light mode styles */
.contact-container.light {
    background-color: #ffffff;
    color: #333333;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.contact-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.contact-intro {
    font-size: 1.2rem;
    margin-bottom: 40px;
    text-align: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-input, .form-textarea {
    padding: 12px;
    margin-bottom: 20px;
    font-size: 1rem;
    border-radius: 6px;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-input {
    height: 40px;
}

.form-textarea {
    height: 200px;
}

.submit-button {
    padding: 12px;
    font-size: 1rem;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button.light {
    background-color: #007bff;
    color: #fff;
}

.submit-button.dark {
    background-color: #333;
    color: #fff;
}

.social-links {
    margin-top: 40px;
}

.social-icon {
    margin: 0 15px;
    color: inherit;
    transition: transform 0.3s ease;
}

.social-icon:hover {
    transform: scale(1.1);
}
